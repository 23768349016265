import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import nft from '../assets/dragon3.png';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

class Story extends Component {

    render() {
        return (

            <div class="boxWrap2Story">
                <div class="boxWrap2Sub">
                    <div class="storyH">Story</div>

                    <div class="about">

                        <img class="nftIcon" src={nft} />

                        <div class="aboutP">
                            <p>Inspired by FLOKI, Next Chinese Zodiac DRAGON and SpaceX Spacecraft.A new perception about crypto in this digital era, we created the most powerful name for our brand, the leader of meme tokens FLOKI mixed with the next chinese zodiac DRAGON and SpaceX's Spacecraft name.</p>
                            <p>Dragon NFT Mechanism * 80%(0.04 BNB) worth of every NFT purchase goes back to the chart as a buy that makes it a green chart after that token to destribute to all NFT holders. The remaining 20% is for the lottery pool. There will be lottery every 1,000 sold NFT’s. The total supply of NFT is 10,000 and each NFT holds 4 digits. (For example, 0000, 2396) If zero winners we'll use funds for next lottery.</p>
                            <p>There will be 3 winners in every lottery draw *1st prize: 80% of the pool *2nd prize :15% *3rd prize: 5%</p>
                            <p>For example * 1k NFT sold out / 40 BNB(Buyback then Rewards to all NFT holders) & 10 BNB lottery * 10k NFT sold out / Total 400 BNB(Buyback then Rewards to all NFT holders) & 100 BNB lottery (if zero winners till 10k NFT sold out)</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Story;

