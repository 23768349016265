import React, { Component } from 'react';
import '../App.css';
import footPrint2 from '../assets/rm.png';
import $ from "jquery";
import wand from '../assets/magic-wand.png';

$(window).on('scroll', function () {
    function isScrollIntoView(elem, index) {
        var docViewTop = $(window).scrollTop();
        var docViewBottom = docViewTop + $(window).height();
        var elemTop = $(elem).offset().top;
        var elemBottom = elemTop + $(window).height() * .5;
        if (elemBottom <= docViewBottom && elemTop >= docViewTop) {
            $(elem).addClass('active');
        }
        if (!(elemBottom <= docViewBottom)) {
            $(elem).removeClass('active');
        }
    }
    var timeline = $('.vertical-scrollable-timeline li');
    Array.from(timeline).forEach(isScrollIntoView);
});

class Roadmap extends Component {


    render() {
        return (

            <div class="rmbg">

                <div class="storyH">ROADMAP</div>

                <div class="timeline-container">
                    <ul class="vertical-scrollable-timeline">
                        <li>
                            <h2>PHASE - I</h2>                           
                            <p><img class="wand" src={wand} />1000 NFTs for sale.</p>
                            <p><img class="wand" src={wand} />Start LOTTERY 4 event.</p>
                            <p><img class="wand" src={wand} />First winners All 4 digits match the numbers.</p>
                            <p><img class="wand" src={wand} />2nd winners last 3 digits match</p>
                            <p><img class="wand" src={wand} />3rd winners last 2 digits match</p>
                            <div class="icon-holder">
                                <img class="footPrint2" src={footPrint2} />
                            </div>
                        </li>
                        <li>
                            <h2>PHASE - II</h2>
                             <p><img class="wand" src={wand} />Sell 2000~5000 NFTs.</p>
                            <p><img class="wand" src={wand} />There will be LOTTERY every 1,000 NFT's when it sold out.</p>
                            <div class="icon-holder">
                                <img class="footPrint2" src={footPrint2} />
                            </div>
                        </li>
                        <li>
                            <h2>PHASE - III</h2>
                             <p><img class="wand" src={wand} />Sell 6,000 to 10,000 NFTs.</p>
                            <p><img class="wand" src={wand} />There will be LOTTERY when sold out, 6000, 7000, 8000, 9000, or 10000 NFTs.</p>
                            <div class="icon-holder">
                                <img class="footPrint2" src={footPrint2} />
                            </div>
                        </li>

                    </ul>
                </div>

            </div>


        );

    }
}

export default Roadmap;