import React, { Component } from 'react';
 
class FAQ extends Component {


  render() {
    return (

      <div id="faq" class="faqbg">

        <div class="accordin reveal">

        <div class="storyH">FAQ</div>

          <details >
            <summary>How to Buy Floki Dragon NFT?</summary>
            <div class="faq__content">
              <p>Connect your Metamask with Website and click "Mint Here".</p>
            </div>
          </details>
          <details>
            <summary>How many designs are available?</summary>
            <div class="faq__content">
            <p>There are many designs. Many artists have drawn them.</p>
            </div>
          </details>
          <details>
            <summary>How to claim Rewards?</summary>
            <div class="faq__content">
            <p>Connect your Metamask with Website and click "Claim Rewards"</p>
            </div>
          </details> 

        </div>
      </div>


    )
  }
}

export default FAQ;

