import './App.css';
import React, { Component } from 'react';
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import CoinbaseWalletSDK, { CoinbaseWalletProvider } from "@coinbase/wallet-sdk";
import Story from './Componets/story';
import FAQ from './Componets/faq';
import Footer from './Componets/footer';
import os from './assets/os.png';
import twitter from './assets/twitter.png';
import Roadmap from './Componets/roadmap';
import $ from 'jquery';
import up from './assets/up-arrow.png';
import navbar from './assets/navigation-bar.png';
import wrong from './assets/wrong.png';
import nft from './assets/dragon1.png';
import nftsub from './assets/dragon4.png';

var Scroll = require('react-scroll');

var Link = Scroll.Link;
var DirectLink = Scroll.DirectLink;
var Element = Scroll.Element;
var Events = Scroll.Events;
var scroll = Scroll.animateScroll;
var scrollSpy = Scroll.scrollSpy;

const opensea = () => {
	window.open("https://opensea.io");
}

const tweet = () => {
	window.open("https://twitter.com/flokidragon_");
}

$(document).ready(function () {
	const stickyButton = $("#sticky-button");
	$(window).on("scroll", function () {
		if ($(this).scrollTop() > 200) {
			stickyButton.css("visibility", "visible");
		} else {
			stickyButton.css("visibility", "hidden");
		}
	});
});

let account;
let mintAmount = 1;
let valueOfNFTs = 0;
let totalSupplyNFT;
let totalSupplyNFT2;
let maxMintNFTs;
let onlyLeft;
let owner;
let publicSale;
let user_input;
let wMintAmount;
let myTokens = [];
let myTokens2 = [];
let adminWhitelistAddresses = [];
let adminPanel = 0;
let adminWhitelistAddresses2 = [];
let maxTokensToBuy = "";
let testingvalue;
let wlMint;
let FinalResult;
let wlMsg = "Whitelist Mint";
let public_sale_cost;
let presalesale_cost;
let MAX_PER_WALLET;
let public_mint_status = false;
let presale_status = false;
let usdtBalances;

const ABI = [
	{
	  "inputs": [
		{
		  "internalType": "string",
		  "name": "_initBaseURI",
		  "type": "string"
		},
		{
		  "internalType": "string",
		  "name": "_initNotRevealedUri",
		  "type": "string"
		},
		{
		  "internalType": "string",
		  "name": "_contractURI",
		  "type": "string"
		}
	  ],
	  "stateMutability": "nonpayable",
	  "type": "constructor"
	},
	{
	  "inputs": [],
	  "name": "ApprovalCallerNotOwnerNorApproved",
	  "type": "error"
	},
	{
	  "inputs": [],
	  "name": "ApprovalQueryForNonexistentToken",
	  "type": "error"
	},
	{
	  "inputs": [],
	  "name": "ApprovalToCurrentOwner",
	  "type": "error"
	},
	{
	  "inputs": [],
	  "name": "ApproveToCaller",
	  "type": "error"
	},
	{
	  "inputs": [],
	  "name": "BalanceQueryForZeroAddress",
	  "type": "error"
	},
	{
	  "inputs": [],
	  "name": "MintToZeroAddress",
	  "type": "error"
	},
	{
	  "inputs": [],
	  "name": "MintZeroQuantity",
	  "type": "error"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "operator",
		  "type": "address"
		}
	  ],
	  "name": "OperatorNotAllowed",
	  "type": "error"
	},
	{
	  "inputs": [],
	  "name": "OwnerIndexOutOfBounds",
	  "type": "error"
	},
	{
	  "inputs": [],
	  "name": "OwnerQueryForNonexistentToken",
	  "type": "error"
	},
	{
	  "inputs": [],
	  "name": "TokenIndexOutOfBounds",
	  "type": "error"
	},
	{
	  "inputs": [],
	  "name": "TransferCallerNotOwnerNorApproved",
	  "type": "error"
	},
	{
	  "inputs": [],
	  "name": "TransferFromIncorrectOwner",
	  "type": "error"
	},
	{
	  "inputs": [],
	  "name": "TransferToNonERC721ReceiverImplementer",
	  "type": "error"
	},
	{
	  "inputs": [],
	  "name": "TransferToZeroAddress",
	  "type": "error"
	},
	{
	  "inputs": [],
	  "name": "URIQueryForNonexistentToken",
	  "type": "error"
	},
	{
	  "anonymous": false,
	  "inputs": [
		{
		  "indexed": true,
		  "internalType": "address",
		  "name": "owner",
		  "type": "address"
		},
		{
		  "indexed": true,
		  "internalType": "address",
		  "name": "approved",
		  "type": "address"
		},
		{
		  "indexed": true,
		  "internalType": "uint256",
		  "name": "tokenId",
		  "type": "uint256"
		}
	  ],
	  "name": "Approval",
	  "type": "event"
	},
	{
	  "anonymous": false,
	  "inputs": [
		{
		  "indexed": true,
		  "internalType": "address",
		  "name": "owner",
		  "type": "address"
		},
		{
		  "indexed": true,
		  "internalType": "address",
		  "name": "operator",
		  "type": "address"
		},
		{
		  "indexed": false,
		  "internalType": "bool",
		  "name": "approved",
		  "type": "bool"
		}
	  ],
	  "name": "ApprovalForAll",
	  "type": "event"
	},
	{
	  "anonymous": false,
	  "inputs": [
		{
		  "indexed": true,
		  "internalType": "address",
		  "name": "previousOwner",
		  "type": "address"
		},
		{
		  "indexed": true,
		  "internalType": "address",
		  "name": "newOwner",
		  "type": "address"
		}
	  ],
	  "name": "OwnershipTransferred",
	  "type": "event"
	},
	{
	  "anonymous": false,
	  "inputs": [
		{
		  "indexed": true,
		  "internalType": "address",
		  "name": "from",
		  "type": "address"
		},
		{
		  "indexed": true,
		  "internalType": "address",
		  "name": "to",
		  "type": "address"
		},
		{
		  "indexed": true,
		  "internalType": "uint256",
		  "name": "tokenId",
		  "type": "uint256"
		}
	  ],
	  "name": "Transfer",
	  "type": "event"
	},
	{
	  "inputs": [],
	  "name": "OPERATOR_FILTER_REGISTRY",
	  "outputs": [
		{
		  "internalType": "contract IOperatorFilterRegistry",
		  "name": "",
		  "type": "address"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address[]",
		  "name": "receiver",
		  "type": "address[]"
		},
		{
		  "internalType": "uint256[]",
		  "name": "quantity",
		  "type": "uint256[]"
		}
	  ],
	  "name": "airdrop",
	  "outputs": [],
	  "stateMutability": "payable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "operator",
		  "type": "address"
		},
		{
		  "internalType": "uint256",
		  "name": "tokenId",
		  "type": "uint256"
		}
	  ],
	  "name": "approve",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "owner",
		  "type": "address"
		}
	  ],
	  "name": "balanceOf",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "claimUSDT",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "contractURI",
	  "outputs": [
		{
		  "internalType": "string",
		  "name": "",
		  "type": "string"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "uint256",
		  "name": "tokenId",
		  "type": "uint256"
		}
	  ],
	  "name": "getApproved",
	  "outputs": [
		{
		  "internalType": "address",
		  "name": "",
		  "type": "address"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "getBaseURI",
	  "outputs": [
		{
		  "internalType": "string",
		  "name": "",
		  "type": "string"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "owner",
		  "type": "address"
		},
		{
		  "internalType": "address",
		  "name": "operator",
		  "type": "address"
		}
	  ],
	  "name": "isApprovedForAll",
	  "outputs": [
		{
		  "internalType": "bool",
		  "name": "",
		  "type": "bool"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "max_per_wallet",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "uint256",
		  "name": "quantity",
		  "type": "uint256"
		}
	  ],
	  "name": "mint",
	  "outputs": [],
	  "stateMutability": "payable",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "name",
	  "outputs": [
		{
		  "internalType": "string",
		  "name": "",
		  "type": "string"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "notRevealedUri",
	  "outputs": [
		{
		  "internalType": "string",
		  "name": "",
		  "type": "string"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "owner",
	  "outputs": [
		{
		  "internalType": "address",
		  "name": "",
		  "type": "address"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "uint256",
		  "name": "tokenId",
		  "type": "uint256"
		}
	  ],
	  "name": "ownerOf",
	  "outputs": [
		{
		  "internalType": "address",
		  "name": "",
		  "type": "address"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "publicSaleCost",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "public_mint_status",
	  "outputs": [
		{
		  "internalType": "bool",
		  "name": "",
		  "type": "bool"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "renounceOwnership",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "_token",
		  "type": "address"
		}
	  ],
	  "name": "rescueToken",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "revealed",
	  "outputs": [
		{
		  "internalType": "bool",
		  "name": "",
		  "type": "bool"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "rewards_rate",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "uint256",
		  "name": "_tokenId",
		  "type": "uint256"
		},
		{
		  "internalType": "uint256",
		  "name": "_salePrice",
		  "type": "uint256"
		}
	  ],
	  "name": "royaltyInfo",
	  "outputs": [
		{
		  "internalType": "address",
		  "name": "",
		  "type": "address"
		},
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "from",
		  "type": "address"
		},
		{
		  "internalType": "address",
		  "name": "to",
		  "type": "address"
		},
		{
		  "internalType": "uint256",
		  "name": "tokenId",
		  "type": "uint256"
		}
	  ],
	  "name": "safeTransferFrom",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "from",
		  "type": "address"
		},
		{
		  "internalType": "address",
		  "name": "to",
		  "type": "address"
		},
		{
		  "internalType": "uint256",
		  "name": "tokenId",
		  "type": "uint256"
		},
		{
		  "internalType": "bytes",
		  "name": "data",
		  "type": "bytes"
		}
	  ],
	  "name": "safeTransferFrom",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "operator",
		  "type": "address"
		},
		{
		  "internalType": "bool",
		  "name": "approved",
		  "type": "bool"
		}
	  ],
	  "name": "setApprovalForAll",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "string",
		  "name": "_newBaseURI",
		  "type": "string"
		}
	  ],
	  "name": "setBaseURI",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "string",
		  "name": "_contractURI",
		  "type": "string"
		}
	  ],
	  "name": "setContractURI",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "uint256",
		  "name": "_MAX_SUPPLY",
		  "type": "uint256"
		}
	  ],
	  "name": "setMAX_SUPPLY",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "uint256",
		  "name": "_max_per_wallet",
		  "type": "uint256"
		}
	  ],
	  "name": "setMax_per_wallet",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "string",
		  "name": "_notRevealedURI",
		  "type": "string"
		}
	  ],
	  "name": "setNotRevealedURI",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "uint256",
		  "name": "_publicSaleCost",
		  "type": "uint256"
		}
	  ],
	  "name": "setPublicSaleCost",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "uint256",
		  "name": "_rewards_rate",
		  "type": "uint256"
		}
	  ],
	  "name": "setRewards_rate",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "_receiver",
		  "type": "address"
		},
		{
		  "internalType": "uint96",
		  "name": "_royaltyFeesInBips",
		  "type": "uint96"
		}
	  ],
	  "name": "setRoyaltyInfo",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "uint256",
		  "name": "_timeDuration",
		  "type": "uint256"
		}
	  ],
	  "name": "setTimeDuration",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "bytes4",
		  "name": "interfaceId",
		  "type": "bytes4"
		}
	  ],
	  "name": "supportsInterface",
	  "outputs": [
		{
		  "internalType": "bool",
		  "name": "",
		  "type": "bool"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "uint256",
		  "name": "bnb_amount_in",
		  "type": "uint256"
		}
	  ],
	  "name": "swapETHForWUSDT",
	  "outputs": [],
	  "stateMutability": "payable",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "symbol",
	  "outputs": [
		{
		  "internalType": "string",
		  "name": "",
		  "type": "string"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "timeDuration",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "toggleReveal",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "toggle_public_mint_status",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "uint256",
		  "name": "index",
		  "type": "uint256"
		}
	  ],
	  "name": "tokenByIndex",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "owner",
		  "type": "address"
		},
		{
		  "internalType": "uint256",
		  "name": "index",
		  "type": "uint256"
		}
	  ],
	  "name": "tokenOfOwnerByIndex",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "uint256",
		  "name": "tokenId",
		  "type": "uint256"
		}
	  ],
	  "name": "tokenURI",
	  "outputs": [
		{
		  "internalType": "string",
		  "name": "",
		  "type": "string"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "totalSupply",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "from",
		  "type": "address"
		},
		{
		  "internalType": "address",
		  "name": "to",
		  "type": "address"
		},
		{
		  "internalType": "uint256",
		  "name": "tokenId",
		  "type": "uint256"
		}
	  ],
	  "name": "transferFrom",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "newOwner",
		  "type": "address"
		}
	  ],
	  "name": "transferOwnership",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "",
		  "type": "address"
		}
	  ],
	  "name": "usdtBalances",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "withdraw",
	  "outputs": [],
	  "stateMutability": "payable",
	  "type": "function"
	}
  ];

const address = "0xc8268fA22acf59A2Ac4e4704f43933fF515F14c7";
let contract;


class App extends Component {

	state = {
		walletAddress: "",
		totalSupply: "",
		currentPrice: "",
		nextPrice: "",
		nextSessionAmount: "",
		onlyLeftAmount: "",
		statusError: false,
		statusLoading: false,
		success: false,
		nftMintingAmount: '1',
		totalValue: "",
		presaleValue: "0",
		maxmint: '',
		_adminPanel: 0,
		_adminWhitelistAddresses: [],
		_adminWhitelistAddresses2: [],
		_maxTokensToBuy: "",
		_testingValue: '',
		_wlMint: '',
		_FinalResult: '',
		_wlMsg: 'Whitelist Mint',
		_public_sale_cost: '',
		_presalesale_cost: '',
		_MAX_PER_WALLET: '',
		_public_mint_status: '',
		_presale_status: '',
		_cost: '',
		_mintStatus: false,
		_navbarOpen: 0,
		_usdtBalances: ''
	}

	async componentDidMount() {

		if (localStorage?.getItem('isWalletConnected') === 'true') {
			const providerOptions = {
				walletconnect: {
					package: WalletConnectProvider, // required
					options: {
						infuraId: "811915bee3744bd38afcf17ecac0f9a6" // required
					}
				},
				coinbasewallet: {
					package: CoinbaseWalletSDK, // Required
					options: {
						appName: "Aterium Universe", // Required
						infuraId: "811915bee3744bd38afcf17ecac0f9a6", // Required
						rpc: "", // Optional if `infuraId` is provided; otherwise it's required
						chainId: 1, // Optional. It defaults to 1 if not provided
						darkMode: true // Optional. Use dark theme, defaults to false
					}
				}
			};

			const web3Modal = new Web3Modal({
				network: "mainnet", // optional
				cacheProvider: true, // optional
				providerOptions // required
			});



			const provider = await web3Modal.connect();

			//  Enable session (triggers QR Code modal)
			await provider.enable();

			const web3 = new Web3(provider);
			console.log("provider : " + provider);
			// Subscribe to accounts change
			provider.on("accountsChanged", (accounts) => {
				console.log(accounts);
			});

			// Subscribe to chainId change
			provider.on("chainChanged", (chainId) => {
				console.log(chainId);
			});

			// Subscribe to provider connection
			provider.on("connect", (info) => {
				console.log(info);
				console.log("I'm LOGGED IN");
			});

			// Subscribe to provider disconnection
			provider.on("disconnect", (error) => {
				console.log(error);
			});

			// test if wallet is connected
			if (web3Modal.cachedProvider) {
				// connected now you can get accounts
				console.log("web3Modal.cachedProvider :" + web3Modal.cachedProvider);
				console.log("provider :" + provider);

				const accounts = await web3.eth.getAccounts();

				account = accounts[0];
				this.setState({ walletAddress: account });

				contract = new web3.eth.Contract(ABI, address);
				console.log("contract :" + contract);

				if (provider) {


					//	(async () => {

					if (web3Modal.cachedProvider != "walletconnect" && web3Modal.cachedProvider != "coinbasewallet") {

						// const chainId = 97;
						const chainId = 56;

						if (window.ethereum.networkVersion !== chainId) {
							try {
								await window.ethereum.request({
									method: 'wallet_switchEthereumChain',
									params: [{ chainId: web3.utils.toHex(chainId) }],
								});
							} catch (err) {
								// This error code indicates that the chain has not been added to MetaMask.
								if (err.code === 4902) {
									await window.ethereum.request({
										method: 'wallet_addEthereumChain',
										params: [
											{
												/*chainName: 'Ethereum Mainnet',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Ethereum Mainnet', decimals: 18, symbol: 'ETH' },
												rpcUrls: ['https://mainnet.infura.io/v3/'],*/

												// chainName: 'Smart Chain - Testnethttps://testnet.bscscan.com',
												// chainId: web3.utils.toHex(chainId),
												// nativeCurrency: { name: 'Smart Chain', decimals: 18, symbol: 'BNB' },
												// rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],

												chainName: 'Smart Chain - Mainnet https://bscscan.com',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Smart Chain', decimals: 18, symbol: 'BNB' },
												rpcUrls: ['https://bsc-dataseed.binance.org/'],
											},
										],
									});
								}
							}
						}


						try {


							usdtBalances = await contract.methods.usdtBalances(account).call();
							this.setState({ _usdtBalances: usdtBalances });
							console.log("usdtBalances:" + usdtBalances);

							totalSupplyNFT = await contract.methods.totalSupply().call();
							this.setState({ totalSupply: totalSupplyNFT });
							console.log("Total Supply:" + totalSupplyNFT);

							publicSale = await contract.methods.balanceOf(account).call();
							this.setState({ myNFTWallet: publicSale });

							public_sale_cost = await contract.methods.publicSaleCost().call();
							this.setState({ _public_sale_cost: public_sale_cost });
							console.log("public_sale_cost :" + public_sale_cost);
							this.setState({ _cost: public_sale_cost });
							this.setState({ _mintStatus: false });

							MAX_PER_WALLET = await contract.methods.max_per_wallet().call();
							this.setState({ _MAX_PER_WALLET: MAX_PER_WALLET });
							console.log("MAX_PER_WALLET :" + MAX_PER_WALLET);

							public_mint_status = await contract.methods.public_mint_status().call();
							this.setState({ _public_mint_status: public_mint_status });
							console.log("public_mint_status :" + public_mint_status);

							owner = await contract.methods.owner().call();
							console.log("Owner" + owner);
							//..........................................................................//
							if (owner == account) {
								console.log("owner : " + owner)
								onlyLeft = 10000 - totalSupplyNFT;

								if (mintAmount > onlyLeft) {
									mintAmount = onlyLeft;
								}

								valueOfNFTs = mintAmount * 0;



								this.setState({ nftMintingAmount: mintAmount });

								this.setState({ totalValue: valueOfNFTs });
							} else {
								mintAmount = 1;

								if (totalSupplyNFT == 10000) {

									onlyLeft = 10000 - totalSupplyNFT;
									mintAmount = onlyLeft;
									this.setState({ msg: "SOLD OUT" });

								} else {
									mintAmount = 1;
									onlyLeft = MAX_PER_WALLET - publicSale;

									if (mintAmount > onlyLeft) {
										mintAmount = onlyLeft;
									}
									//mintAmount = onlyLeft;

									valueOfNFTs = mintAmount * this.state._cost;



									this.setState({ nftMintingAmount: mintAmount });

									this.setState({ totalValue: valueOfNFTs });
								}
							}
							///................................///



							//..........................................................................//

						} catch (err) {
							console.log("err: " + err);

						}

					} else if (web3Modal.cachedProvider == "walletconnect") {

						// const chainId = 97;
						const chainId = 56;

						if (WalletConnectProvider.networkVersion !== chainId) {
							try {
								await WalletConnectProvider.request({
									method: 'wallet_switchEthereumChain',
									params: [{ chainId: web3.utils.toHex(chainId) }],
								});
							} catch (err) {
								// This error code indicates that the chain has not been added to MetaMask.
								if (err.code === 4902) {
									await window.ethereum.request({
										method: 'wallet_addEthereumChain',
										params: [
											{
												/*chainName: 'Ethereum Mainnet',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Ethereum Mainnet', decimals: 18, symbol: 'ETH' },
												rpcUrls: ['https://mainnet.infura.io/v3/'],*/

												// chainName: 'Smart Chain - Testnethttps://testnet.bscscan.com',
												// chainId: web3.utils.toHex(chainId),
												// nativeCurrency: { name: 'Smart Chain', decimals: 18, symbol: 'BNB' },
												// rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],

												chainName: 'Smart Chain - Mainnet https://bscscan.com',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Smart Chain', decimals: 18, symbol: 'BNB' },
												rpcUrls: ['https://bsc-dataseed.binance.org/'],
											},
										],
									});
								}
							}
						}


						try {


							usdtBalances = await contract.methods.usdtBalances(account).call();
							this.setState({ _usdtBalances: usdtBalances });
							console.log("usdtBalances:" + usdtBalances);

							totalSupplyNFT = await contract.methods.totalSupply().call();
							this.setState({ totalSupply: totalSupplyNFT });
							console.log("Total Supply:" + totalSupplyNFT);

							publicSale = await contract.methods.balanceOf(account).call();
							this.setState({ myNFTWallet: publicSale });

							public_sale_cost = await contract.methods.publicSaleCost().call();
							this.setState({ _public_sale_cost: public_sale_cost });
							console.log("public_sale_cost :" + public_sale_cost);
							this.setState({ _cost: public_sale_cost });
							this.setState({ _mintStatus: false });

							MAX_PER_WALLET = await contract.methods.max_per_wallet().call();
							this.setState({ _MAX_PER_WALLET: MAX_PER_WALLET });
							console.log("MAX_PER_WALLET :" + MAX_PER_WALLET);

							public_mint_status = await contract.methods.public_mint_status().call();
							this.setState({ _public_mint_status: public_mint_status });
							console.log("public_mint_status :" + public_mint_status);

							owner = await contract.methods.owner().call();
							console.log("Owner" + owner);
							//..........................................................................//
							if (owner == account) {
								console.log("owner : " + owner)
								onlyLeft = 10000 - totalSupplyNFT;

								if (mintAmount > onlyLeft) {
									mintAmount = onlyLeft;
								}

								valueOfNFTs = mintAmount * 0;



								this.setState({ nftMintingAmount: mintAmount });

								this.setState({ totalValue: valueOfNFTs });
							} else {
								mintAmount = 1;

								if (totalSupplyNFT == 10000) {

									onlyLeft = 10000 - totalSupplyNFT;
									mintAmount = onlyLeft;
									this.setState({ msg: "SOLD OUT" });

								} else {
									mintAmount = 1;
									onlyLeft = MAX_PER_WALLET - publicSale;

									if (mintAmount > onlyLeft) {
										mintAmount = onlyLeft;
									}
									//mintAmount = onlyLeft;

									valueOfNFTs = mintAmount * this.state._cost;



									this.setState({ nftMintingAmount: mintAmount });

									this.setState({ totalValue: valueOfNFTs });
								}
							}
							///................................///



							//..........................................................................//

						} catch (err) {
							console.log("err: " + err);

						}


					} else if (web3Modal.cachedProvider == "coinbasewallet") {

						// const chainId = 97;
						const chainId = 56;

						if (CoinbaseWalletProvider.networkVersion !== chainId) {
							try {
								await CoinbaseWalletProvider.request({
									method: 'wallet_switchEthereumChain',
									params: [{ chainId: web3.utils.toHex(chainId) }],
								});
							} catch (err) {
								// This error code indicates that the chain has not been added to MetaMask.
								if (err.code === 4902) {
									await CoinbaseWalletProvider.request({
										method: 'wallet_addEthereumChain',
										params: [
											{
												/*chainName: 'Ethereum Mainnet',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Ethereum Mainnet', decimals: 18, symbol: 'ETH' },
												rpcUrls: ['https://mainnet.infura.io/v3/'],*/

												// chainName: 'Smart Chain - Testnethttps://testnet.bscscan.com',
												// chainId: web3.utils.toHex(chainId),
												// nativeCurrency: { name: 'Smart Chain', decimals: 18, symbol: 'BNB' },
												// rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],

												chainName: 'Smart Chain - Mainnet https://bscscan.com',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Smart Chain', decimals: 18, symbol: 'BNB' },
												rpcUrls: ['https://bsc-dataseed.binance.org/'],
											},
										],
									});
								}
							}
						}


						try {


							usdtBalances = await contract.methods.usdtBalances(account).call();
							this.setState({ _usdtBalances: usdtBalances });
							console.log("usdtBalances:" + usdtBalances);

							totalSupplyNFT = await contract.methods.totalSupply().call();
							this.setState({ totalSupply: totalSupplyNFT });
							console.log("Total Supply:" + totalSupplyNFT);

							publicSale = await contract.methods.balanceOf(account).call();
							this.setState({ myNFTWallet: publicSale });

							public_sale_cost = await contract.methods.publicSaleCost().call();
							this.setState({ _public_sale_cost: public_sale_cost });
							console.log("public_sale_cost :" + public_sale_cost);
							this.setState({ _cost: public_sale_cost });
							this.setState({ _mintStatus: false });

							MAX_PER_WALLET = await contract.methods.max_per_wallet().call();
							this.setState({ _MAX_PER_WALLET: MAX_PER_WALLET });
							console.log("MAX_PER_WALLET :" + MAX_PER_WALLET);

							public_mint_status = await contract.methods.public_mint_status().call();
							this.setState({ _public_mint_status: public_mint_status });
							console.log("public_mint_status :" + public_mint_status);

							owner = await contract.methods.owner().call();
							console.log("Owner" + owner);
							//..........................................................................//
							if (owner == account) {
								console.log("owner : " + owner)
								onlyLeft = 10000 - totalSupplyNFT;

								if (mintAmount > onlyLeft) {
									mintAmount = onlyLeft;
								}

								valueOfNFTs = mintAmount * 0;



								this.setState({ nftMintingAmount: mintAmount });

								this.setState({ totalValue: valueOfNFTs });
							} else {
								mintAmount = 1;

								if (totalSupplyNFT == 10000) {

									onlyLeft = 10000 - totalSupplyNFT;
									mintAmount = onlyLeft;
									this.setState({ msg: "SOLD OUT" });

								} else {
									mintAmount = 1;
									onlyLeft = MAX_PER_WALLET - publicSale;

									if (mintAmount > onlyLeft) {
										mintAmount = onlyLeft;
									}
									//mintAmount = onlyLeft;

									valueOfNFTs = mintAmount * this.state._cost;



									this.setState({ nftMintingAmount: mintAmount });

									this.setState({ totalValue: valueOfNFTs });
								}
							}
							///................................///



							//..........................................................................//

						} catch (err) {
							console.log("err: " + err);

						}


					}


					//})();

					//.....................................................................//

					// Legacy providers may only have ethereum.sendAsync
					const chainId = await provider.request({
						method: 'eth_chainId'
					})

				} else {

					// if the provider is not detected, detectEthereumProvider resolves to null
					console.error('Please install a Valid Wallet');
					alert('A valid provider could not be found!');

				}

			}

		} else {

			console.log("NOT CONNECTED");

		}
	}

	onSubmitMinus = async event => {
		event.preventDefault();

		mintAmount = mintAmount - 1;

		if (mintAmount < 1) {
			mintAmount = 1
		}


		if (owner == account) {
			console.log("owner : " + owner)
			onlyLeft = 10000 - totalSupplyNFT;

			if (mintAmount > onlyLeft) {
				mintAmount = onlyLeft;
			}

			valueOfNFTs = mintAmount * 0;
			wMintAmount = mintAmount;

			this.setState({ nftMintingAmount: mintAmount });

			this.setState({ totalValue: valueOfNFTs });
		} else {


			if (totalSupplyNFT < 10000) {

				onlyLeft = MAX_PER_WALLET - publicSale;

				if (mintAmount > onlyLeft) {
					mintAmount = onlyLeft;
				}
				valueOfNFTs = mintAmount * this.state._cost;
				wMintAmount = mintAmount;


				this.setState({ nftMintingAmount: mintAmount });

				this.setState({ totalValue: valueOfNFTs });

			}
		}
	}

	onSubmitPlus = async event => {
		event.preventDefault();

		//,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,
		mintAmount = mintAmount + 1;

		if (owner == account) {
			console.log("owner : " + owner)
			onlyLeft = 10000 - totalSupplyNFT;

			if (mintAmount > onlyLeft) {
				mintAmount = onlyLeft;
			}

			valueOfNFTs = mintAmount * 0;
			wMintAmount = mintAmount;


			this.setState({ nftMintingAmount: mintAmount });

			this.setState({ totalValue: valueOfNFTs });
		} else {

			if (totalSupplyNFT < 10000) {

				onlyLeft = MAX_PER_WALLET - publicSale;
				console.log(onlyLeft);

				if (mintAmount > onlyLeft) {
					mintAmount = onlyLeft;
				}
				valueOfNFTs = mintAmount * this.state._cost;
				wMintAmount = mintAmount;

				this.setState({ nftMintingAmount: mintAmount });

				this.setState({ totalValue: valueOfNFTs });

			}
		}
	}

	onSubmit2 = async event => {
		event.preventDefault();

		console.log(this.state.walletAddress);

		try {
			let owner = await contract.methods.owner().call();


			if (account != owner) {


				try {

					console.log("minAmount:" + mintAmount);
					console.log("valueOfNFTs:" + valueOfNFTs);
					console.log("totalValue:" + this.state.totalValue);

					this.setState({ statusError: false, statusLoading: true });
					await contract.methods.mint(mintAmount * 1).send({ from: account, value: this.state.totalValue * 1 });
					window.location.reload(true);
					this.setState({ statusLoading: false, success: true });
					console.log("Mint Amount :" + this.state.mintAmount);

				} catch (err) {
					this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
					console.log(err);


				}
			} else {

				try {


					console.log("minAmount:" + mintAmount);
					console.log("valueOfNFTs:" + valueOfNFTs);


					this.setState({ statusError: false, statusLoading: true });
					await contract.methods.mint(mintAmount * 1).send({ from: account, value: this.state.totalValue * 0 });
					window.location.reload(true);
					this.setState({ statusLoading: false, success: true });
					console.log("Mint Amount :" + this.state.mintAmount);

				} catch (err) {
					this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
					console.log(err);


				}






			}
		} catch (err) {

			console.log(err);

		}
	}

	claimUSDT = async event => {
		event.preventDefault();

		console.log(this.state.walletAddress);

		try {

			await contract.methods.claimUSDT().send({ from: account });
			window.location.reload(true);

		} catch (err) {
			console.log(err);
		}

	}

	walletConnect = async event => {
		event.preventDefault();

		const providerOptions = {
			walletconnect: {
				package: WalletConnectProvider, // required
				options: {
					infuraId: "811915bee3744bd38afcf17ecac0f9a6" // required
				}
			},
			coinbasewallet: {
				package: CoinbaseWalletSDK, // Required
				options: {
					appName: "Aterium Universe", // Required
					infuraId: "811915bee3744bd38afcf17ecac0f9a6", // Required
					rpc: "", // Optional if `infuraId` is provided; otherwise it's required
					chainId: 1, // Optional. It defaults to 1 if not provided
					darkMode: true // Optional. Use dark theme, defaults to false
				}
			}
		};

		const web3Modal = new Web3Modal({
			network: "mainnet", // optional
			cacheProvider: true, // optional
			providerOptions // required
		});

		const provider = await web3Modal.connect();

		//  Enable session (triggers QR Code modal)
		await provider.enable();

		const web3 = new Web3(provider);
		console.log("provider : " + provider);
		// Subscribe to accounts change
		provider.on("accountsChanged", (accounts) => {
			console.log(accounts);
		});

		// Subscribe to chainId change
		provider.on("chainChanged", (chainId) => {
			console.log(chainId);
		});

		// Subscribe to provider connection
		provider.on("connect", (info) => {
			console.log(info);
			console.log("I'm LOGGED IN");
		});

		// Subscribe to provider disconnection
		provider.on("disconnect", (error) => {
			console.log(error);
		});

		// test if wallet is connected
		if (web3Modal.cachedProvider) {
			// connected now you can get accounts
			console.log("web3Modal.cachedProvider :" + web3Modal.cachedProvider);
			console.log("provider :" + provider);

			const accounts = await web3.eth.getAccounts();

			account = accounts[0];
			this.setState({ walletAddress: account });

			contract = new web3.eth.Contract(ABI, address);
			console.log("contract :" + contract);

			if (provider) {


				//	(async () => {

				if (web3Modal.cachedProvider != "walletconnect" && web3Modal.cachedProvider != "coinbasewallet") {

					// const chainId = 97;
					const chainId = 56;

					if (window.ethereum.networkVersion !== chainId) {
						try {
							await window.ethereum.request({
								method: 'wallet_switchEthereumChain',
								params: [{ chainId: web3.utils.toHex(chainId) }],
							});
						} catch (err) {
							// This error code indicates that the chain has not been added to MetaMask.
							if (err.code === 4902) {
								await window.ethereum.request({
									method: 'wallet_addEthereumChain',
									params: [
										{
											/*chainName: 'Ethereum Mainnet',
											chainId: web3.utils.toHex(chainId),
											nativeCurrency: { name: 'Ethereum Mainnet', decimals: 18, symbol: 'ETH' },
											rpcUrls: ['https://mainnet.infura.io/v3/'],*/

											// chainName: 'Smart Chain - Testnethttps://testnet.bscscan.com',
											// chainId: web3.utils.toHex(chainId),
											// nativeCurrency: { name: 'Smart Chain', decimals: 18, symbol: 'BNB' },
											// rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],

											chainName: 'Smart Chain - Mainnet https://bscscan.com',
											chainId: web3.utils.toHex(chainId),
											nativeCurrency: { name: 'Smart Chain', decimals: 18, symbol: 'BNB' },
											rpcUrls: ['https://bsc-dataseed.binance.org/'],
										},
									],
								});
							}
						}
					}


					try {

						try {
							localStorage.setItem('isWalletConnected', true);
						} catch (ex) {
							console.log(ex)
						}

						usdtBalances = await contract.methods.usdtBalances(account).call();
						this.setState({ _usdtBalances: usdtBalances });
						console.log("usdtBalances:" + usdtBalances);

						totalSupplyNFT = await contract.methods.totalSupply().call();
						this.setState({ totalSupply: totalSupplyNFT });
						console.log("Total Supply:" + totalSupplyNFT);

						publicSale = await contract.methods.balanceOf(account).call();
						this.setState({ myNFTWallet: publicSale });

						public_sale_cost = await contract.methods.publicSaleCost().call();
						this.setState({ _public_sale_cost: public_sale_cost });
						console.log("public_sale_cost :" + public_sale_cost);
						this.setState({ _cost: public_sale_cost });
						this.setState({ _mintStatus: false });

						MAX_PER_WALLET = await contract.methods.max_per_wallet().call();
						this.setState({ _MAX_PER_WALLET: MAX_PER_WALLET });
						console.log("MAX_PER_WALLET :" + MAX_PER_WALLET);

						public_mint_status = await contract.methods.public_mint_status().call();
						this.setState({ _public_mint_status: public_mint_status });
						console.log("public_mint_status :" + public_mint_status);

						owner = await contract.methods.owner().call();
						console.log("Owner" + owner);
						//..........................................................................//
						if (owner == account) {
							console.log("owner : " + owner)
							onlyLeft = 10000 - totalSupplyNFT;

							if (mintAmount > onlyLeft) {
								mintAmount = onlyLeft;
							}

							valueOfNFTs = mintAmount * 0;



							this.setState({ nftMintingAmount: mintAmount });

							this.setState({ totalValue: valueOfNFTs });
						} else {
							mintAmount = 1;

							if (totalSupplyNFT == 10000) {

								onlyLeft = 10000 - totalSupplyNFT;
								mintAmount = onlyLeft;
								this.setState({ msg: "SOLD OUT" });

							} else {
								mintAmount = 1;
								onlyLeft = MAX_PER_WALLET - publicSale;

								if (mintAmount > onlyLeft) {
									mintAmount = onlyLeft;
								}
								//mintAmount = onlyLeft;

								valueOfNFTs = mintAmount * this.state._cost;



								this.setState({ nftMintingAmount: mintAmount });

								this.setState({ totalValue: valueOfNFTs });
							}
						}
						///................................///



						//..........................................................................//

					} catch (err) {
						console.log("err: " + err);

					}

				} else if (web3Modal.cachedProvider == "walletconnect") {

					// const chainId = 97;
					const chainId = 56;

					if (WalletConnectProvider.networkVersion !== chainId) {
						try {
							await WalletConnectProvider.request({
								method: 'wallet_switchEthereumChain',
								params: [{ chainId: web3.utils.toHex(chainId) }],
							});
						} catch (err) {
							// This error code indicates that the chain has not been added to MetaMask.
							if (err.code === 4902) {
								await window.ethereum.request({
									method: 'wallet_addEthereumChain',
									params: [
										{
											/*chainName: 'Ethereum Mainnet',
											chainId: web3.utils.toHex(chainId),
											nativeCurrency: { name: 'Ethereum Mainnet', decimals: 18, symbol: 'ETH' },
											rpcUrls: ['https://mainnet.infura.io/v3/'],*/

											// chainName: 'Smart Chain - Testnethttps://testnet.bscscan.com',
											// chainId: web3.utils.toHex(chainId),
											// nativeCurrency: { name: 'Smart Chain', decimals: 18, symbol: 'BNB' },
											// rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],

											chainName: 'Smart Chain - Mainnet https://bscscan.com',
											chainId: web3.utils.toHex(chainId),
											nativeCurrency: { name: 'Smart Chain', decimals: 18, symbol: 'BNB' },
											rpcUrls: ['https://bsc-dataseed.binance.org/'],
										},
									],
								});
							}
						}
					}


					try {

						try {
							localStorage.setItem('isWalletConnected', true);
						} catch (ex) {
							console.log(ex)
						}

						usdtBalances = await contract.methods.usdtBalances(account).call();
						this.setState({ _usdtBalances: usdtBalances });
						console.log("usdtBalances:" + usdtBalances);

						totalSupplyNFT = await contract.methods.totalSupply().call();
						this.setState({ totalSupply: totalSupplyNFT });
						console.log("Total Supply:" + totalSupplyNFT);

						publicSale = await contract.methods.balanceOf(account).call();
						this.setState({ myNFTWallet: publicSale });

						public_sale_cost = await contract.methods.publicSaleCost().call();
						this.setState({ _public_sale_cost: public_sale_cost });
						console.log("public_sale_cost :" + public_sale_cost);
						this.setState({ _cost: public_sale_cost });
						this.setState({ _mintStatus: false });

						MAX_PER_WALLET = await contract.methods.max_per_wallet().call();
						this.setState({ _MAX_PER_WALLET: MAX_PER_WALLET });
						console.log("MAX_PER_WALLET :" + MAX_PER_WALLET);

						public_mint_status = await contract.methods.public_mint_status().call();
						this.setState({ _public_mint_status: public_mint_status });
						console.log("public_mint_status :" + public_mint_status);

						owner = await contract.methods.owner().call();
						console.log("Owner" + owner);
						//..........................................................................//
						if (owner == account) {
							console.log("owner : " + owner)
							onlyLeft = 10000 - totalSupplyNFT;

							if (mintAmount > onlyLeft) {
								mintAmount = onlyLeft;
							}

							valueOfNFTs = mintAmount * 0;



							this.setState({ nftMintingAmount: mintAmount });

							this.setState({ totalValue: valueOfNFTs });
						} else {
							mintAmount = 1;

							if (totalSupplyNFT == 10000) {

								onlyLeft = 10000 - totalSupplyNFT;
								mintAmount = onlyLeft;
								this.setState({ msg: "SOLD OUT" });

							} else {
								mintAmount = 1;
								onlyLeft = MAX_PER_WALLET - publicSale;

								if (mintAmount > onlyLeft) {
									mintAmount = onlyLeft;
								}
								//mintAmount = onlyLeft;

								valueOfNFTs = mintAmount * this.state._cost;



								this.setState({ nftMintingAmount: mintAmount });

								this.setState({ totalValue: valueOfNFTs });
							}
						}
						///................................///



						//..........................................................................//

					} catch (err) {
						console.log("err: " + err);

					}


				} else if (web3Modal.cachedProvider == "coinbasewallet") {

					// const chainId = 97;
					const chainId = 56;

					if (CoinbaseWalletProvider.networkVersion !== chainId) {
						try {
							await CoinbaseWalletProvider.request({
								method: 'wallet_switchEthereumChain',
								params: [{ chainId: web3.utils.toHex(chainId) }],
							});
						} catch (err) {
							// This error code indicates that the chain has not been added to MetaMask.
							if (err.code === 4902) {
								await CoinbaseWalletProvider.request({
									method: 'wallet_addEthereumChain',
									params: [
										{
											/*chainName: 'Ethereum Mainnet',
											chainId: web3.utils.toHex(chainId),
											nativeCurrency: { name: 'Ethereum Mainnet', decimals: 18, symbol: 'ETH' },
											rpcUrls: ['https://mainnet.infura.io/v3/'],*/

											// chainName: 'Smart Chain - Testnethttps://testnet.bscscan.com',
											// chainId: web3.utils.toHex(chainId),
											// nativeCurrency: { name: 'Smart Chain', decimals: 18, symbol: 'BNB' },
											// rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],

											chainName: 'Smart Chain - Mainnet https://bscscan.com',
											chainId: web3.utils.toHex(chainId),
											nativeCurrency: { name: 'Smart Chain', decimals: 18, symbol: 'BNB' },
											rpcUrls: ['https://bsc-dataseed.binance.org/'],
										},
									],
								});
							}
						}
					}


					try {

						try {
							localStorage.setItem('isWalletConnected', true);
						} catch (ex) {
							console.log(ex)
						}

						usdtBalances = await contract.methods.usdtBalances(account).call();
						this.setState({ _usdtBalances: usdtBalances });
						console.log("usdtBalances:" + usdtBalances);

						totalSupplyNFT = await contract.methods.totalSupply().call();
						this.setState({ totalSupply: totalSupplyNFT });
						console.log("Total Supply:" + totalSupplyNFT);

						publicSale = await contract.methods.balanceOf(account).call();
						this.setState({ myNFTWallet: publicSale });

						public_sale_cost = await contract.methods.publicSaleCost().call();
						this.setState({ _public_sale_cost: public_sale_cost });
						console.log("public_sale_cost :" + public_sale_cost);
						this.setState({ _cost: public_sale_cost });
						this.setState({ _mintStatus: false });

						MAX_PER_WALLET = await contract.methods.max_per_wallet().call();
						this.setState({ _MAX_PER_WALLET: MAX_PER_WALLET });
						console.log("MAX_PER_WALLET :" + MAX_PER_WALLET);

						public_mint_status = await contract.methods.public_mint_status().call();
						this.setState({ _public_mint_status: public_mint_status });
						console.log("public_mint_status :" + public_mint_status);

						owner = await contract.methods.owner().call();
						console.log("Owner" + owner);
						//..........................................................................//
						if (owner == account) {
							console.log("owner : " + owner)
							onlyLeft = 10000 - totalSupplyNFT;

							if (mintAmount > onlyLeft) {
								mintAmount = onlyLeft;
							}

							valueOfNFTs = mintAmount * 0;



							this.setState({ nftMintingAmount: mintAmount });

							this.setState({ totalValue: valueOfNFTs });
						} else {
							mintAmount = 1;

							if (totalSupplyNFT == 10000) {

								onlyLeft = 10000 - totalSupplyNFT;
								mintAmount = onlyLeft;
								this.setState({ msg: "SOLD OUT" });

							} else {
								mintAmount = 1;
								onlyLeft = MAX_PER_WALLET - publicSale;

								if (mintAmount > onlyLeft) {
									mintAmount = onlyLeft;
								}
								//mintAmount = onlyLeft;

								valueOfNFTs = mintAmount * this.state._cost;



								this.setState({ nftMintingAmount: mintAmount });

								this.setState({ totalValue: valueOfNFTs });
							}
						}
						///................................///



						//..........................................................................//

					} catch (err) {
						console.log("err: " + err);

					}


				}


				//})();

				//.....................................................................//

				// Legacy providers may only have ethereum.sendAsync
				const chainId = await provider.request({
					method: 'eth_chainId'
				})

			} else {

				// if the provider is not detected, detectEthereumProvider resolves to null
				console.error('Please install a Valid Wallet');
				alert('A valid provider could not be found!');

			}

		}

	}

	walletDisconnect = async event => {
		event.preventDefault();


		const providerOptions = {
			walletconnect: {
				package: WalletConnectProvider, // required
				options: {
					infuraId: "bf933c3446b3464c988114813a1360ac" // required
				}
			}
		};

		const web3Modal = new Web3Modal({
			network: "mainnet", // optional
			cacheProvider: true, // optional
			providerOptions // required
		});

		// disconnect wallet
		web3Modal.clearCachedProvider();
		window.location.reload();

		try {
			localStorage.setItem('isWalletConnected', false)
		} catch (ex) {
			console.log(ex)
		}

	}

	navbarOpen = async event => {
		event.preventDefault();

		this.setState({ _navbarOpen: 1 });
	}

	closeNav = () => {
		const navBar = document.querySelector(".littleNav");
		navBar.classList.add("hidden");
	}

	closeNav = async event => {
		event.preventDefault();

		this.setState({ _navbarOpen: 0 });
	}


	render() {

		return (

			<div class="allWrap">
				<div class="light">

					<div class="cont">

						<Element name="up">

							{this.state._navbarOpen < 1 ?
								(

									<div class="miniBarMain">
										<div class="connect2">
											{this.state.walletAddress === '' ?
												(<form onSubmit={this.walletConnect}>
													<button class="button-62">CONNECT WALLET</button>
												</form>) : (<form onSubmit={this.walletDisconnect}><button class="button-62" >
													{this.state.walletAddress.slice(0, 3) + "..." + this.state.walletAddress.slice(39, 42)}</button></form>)}

										</div>
										<div class="navbarMain"><img class="navbar" onClick={this.navbarOpen} src={navbar} /></div>
									</div>) :
								(<div class="miniBarMain">
									<div class="connect2">
										{this.state.walletAddress === '' ?
											(<form onSubmit={this.walletConnect}>
												<button class="button-62">CONNECT WALLET</button>
											</form>) : (<form onSubmit={this.walletDisconnect}><button class="button-62" >
												{this.state.walletAddress.slice(0, 3) + "..." + this.state.walletAddress.slice(39, 42)}</button></form>)}

									</div>
									<div class="navbarMain"><img class="navbar" onClick={this.closeNav} src={wrong} /></div>
								</div>)}

							<div class="headers">

								<div class="h1">
									<div class="connect">
										<div ><Link activeClass="" id="fontSize" to="story" spy={true} smooth={true} duration={550}>STORY</Link></div>
										<div ><Link activeClass="" id="fontSize" to="mint" spy={true} smooth={true} duration={550}>MINT</Link></div>
										<div ><Link activeClass="" id="fontSize" to="rm" spy={true} smooth={true} duration={550}>ROADMAP</Link></div>
										<div ><Link activeClass="" id="fontSize" to="faq" spy={true} smooth={true} duration={550}>FAQ</Link></div>

									</div>

									<div class="right">

										<div class="icons">
											<img onClick={opensea} src={os} />
											<img onClick={tweet} src={twitter} />
										</div>
										<div class="connect2">
											{this.state.walletAddress === '' ?
												(<form onSubmit={this.walletConnect}>
													<button class="button-62">CONNECT WALLET</button>
												</form>) : (<form onSubmit={this.walletDisconnect}><button class="button-62" >
													{this.state.walletAddress.slice(0, 3) + "..." + this.state.walletAddress.slice(39, 42)}</button></form>)}

										</div>

									</div>

								</div>
							</div>

							{this.state._navbarOpen > 0 ?
								(<div class="littleNav" data-aos="fade-left">

									<div ><Link activeClass="" id="fontSize2" to="story" spy={true} smooth={true} duration={550}>STORY</Link></div>
									<div ><Link activeClass="" id="fontSize2" to="mint" spy={true} smooth={true} duration={550}>MINT</Link></div>
									<div ><Link activeClass="" id="fontSize2" to="rm" spy={true} smooth={true} duration={550}>ROADMAP</Link></div>
									<div ><Link activeClass="" id="fontSize2" to="faq" spy={true} smooth={true} duration={550}>FAQ</Link></div>


									<div class="icons">
										<img onClick={opensea} src={os} />
										<img onClick={tweet} src={twitter} />
									</div>
								</div>) : null}
						</Element>

						<div class="introduction">

							<div class="in2">
								Floki Dragon NFTs
							</div>
							<img class="nft" src={nft} />

						</div>


					</div>

					<Element name="story">
						<Story />
					</Element>

					<Element name="mint">
						<div class="boxWrap2Mint">

							<div class="storyH">Mint</div>

							<div class="mintDiv">

								<div class="mintImgDiv"><img class="mintImg" src={nftsub} /></div>
								<div class="mintCon">
									<div class="totalSupply">{this.state.totalSupply}/10000</div>
									<div class="price"><div>Mint Price 0.05 BNB</div></div>

									<div class="minting_count_button">
										<div class="center">
											<form onSubmit={this.onSubmitMinus}>
												<button class="btnfos-0-2" type="submit">-</button>
											</form>
										</div>

										<div>
											<div class="nftminter2"><span class="nftamount">{this.state.nftMintingAmount}</span></div>
										</div>

										<div class="center">
											<form onSubmit={this.onSubmitPlus}>
												<button class="btnfos-0-2" type="submit">+</button>
											</form>
										</div>
									</div>

									{this.state.walletAddress === '' ?

										(<div class="mintbuttondiv">
											<form onSubmit={this.walletConnect}>
												<button class="btnfos-0-3">Connect</button>
											</form></div>) :
										(<div class="mintbuttondiv">
											{this.state._mintStatus === true ? (
												<div>

													<form onSubmit={this.onSubmit2}>
														<button class="btnfos-0-3" type="submit">
															Mint Here</button>
													</form>

												</div>
											) : (<form onSubmit={this.onSubmit2}>
												<button class="btnfos-0-3" type="submit">
													Mint Here</button>
											</form>)}
										</div>)}
									<div>

										{this.state.statusError ? (
											<div class="errorMessage">
												<div >Sorry, something went wrong please try again later</div>
											</div>)
											: null}

										{this.state.statusLoading ? (
											<div class="loadingContainer">
												<div>
													<div class="loadingText">Minting your NFTs</div>
													<div class="loadTextSub">(this can take up to 30 seconds)</div>
												</div>
											</div>)
											: null}

										{this.state.success ? (

											<div><div class="successfully">MINTING SUCCESSFUL</div>
												<div class="link"></div></div>)
											: null}

									</div>

								</div>
							</div>

							<div class="rewardsSection">
								<div class="rewards">{(this.state._usdtBalances / 1000000000).toFixed(2)} Rewards Earned</div>
								<button onClick={this.claimUSDT} class="rewardsBtn">Claim Rewards</button>
							</div>

						</div>
					</Element>


					<Element name="rm">
						<Roadmap />
					</Element>

					<Element name="faq">
						<FAQ />
					</Element>

					<Footer />

				</div>

				<Link id="sticky-button" to="up" spy={true} smooth={true} duration={550}> <img class="stickyBtn2" src={up} /> </Link>

			</div >)
	}
}

export default App;
